import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Image from "../../components/image"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Mediaguide = () => {
  const data = useStaticQuery(graphql`
    query MediaguideEnglishQuery {
      nummern: file(relativePath: { eq: "mediaguide/nummern.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="mediaguide">
      <Seo
        title="Media-Guide"
        description="Mit dem Media-Guide erfahren Sie spannende Hintergründe zu den Objekten und Themen der Dauerausstellung. Der Media-Guide ist inklusiv: Er enthält Inhalte in Leichter Sprache und Gebärdensprache, Angebote für blinde und sehbeeinträchtigte Menschen und in den Sprachen Deutsch, Englisch und Französisch."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Media-Guide",
              link: "/mediaguide",
            },
            {
              title: "English",
              link: "/mediaguide/english",
            },
          ]}
        />
        <PageTitle>Audioguide and translations in English</PageTitle>
        <Paragraph>
          There is a media guide available for the tour through the permanent
          exhibition. It includes an English audio tour and translations of all
          the exhibition texts. The media guide can be borrowed at the museums
          checkout area or downloaded on one's phone. The audio tour permits the
          visitors to follow their own path through the exhibition, either room
          by room or from one object to the next.
        </Paragraph>
        <Grid columns={[1, 1, 2]}>
          <Paragraph>
            There is a recurring icon with a number next to each exhibition
            text. When the number is typed into the media guide, the matching
            translation for the given room and object texts appears. The English
            texts panels can also be read sequentially room by room.
          </Paragraph>
          <Image
            image={data.nummern.childImageSharp.gatsbyImageData}
            alt="Ein aufgeschlagenes Buch mit Hölderlins Übersetzung der ersten pythischen Ode"
          />
        </Grid>
      </Stack>
    </Layout>
  )
}

export default Mediaguide
