import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useThemeUI } from "theme-ui"
import Inline from "./inline"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Breadcrumb = ({ items, linkToHome }) => {
  const context = useThemeUI()
  const { theme } = context

  const renderNavItem = (item, index) => {
    return item ? (
      <Link
        to={item.link}
        activeClassName="active"
        partiallyActive={item.partiallyActive}
        key={`link_${index}`}
        sx={{
          fontFamily: "sans",
          fontWeight: "bold",
          "&:hover": {
            color: theme.colors.primary,
          },
          "&.active": {
            fontWeight: "normal",
            cursor: "default",
            "&:hover": {
              color: "text",
            },
          },
        }}
      >
        {item.title}
      </Link>
    ) : null
  }

  return (
    <nav aria-label="Breadcrumb">
      <Inline as="ol">
        {linkToHome &&
          renderNavItem({
            title: "Startseite",
            link: "/",
          })}
        {items.map((item) => renderNavItem(item))}
      </Inline>
    </nav>
  )
}

Breadcrumb.propTypes = {
  linkToHome: PropTypes.bool,
}

Breadcrumb.defaultProps = {
  linkToHome: true,
}

export default Breadcrumb
