import React from "react"

import Heading from "./heading"
import Box from "./box"
import Stack from "./stack"

const PageTitle = (props) => {
  const { children } = props
  return (
    <Stack space={6}>
      <Box width={[6, 8]} height={[1, 2]} bg="primary" />
      <Heading as="h1" level={1} sx={{ lang: "de", hyphens: "auto" }}>
        {children}
      </Heading>
    </Stack>
  )
}

export default PageTitle
